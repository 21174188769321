/*
@File: Rania LAKOUD 2023

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader CSS
** - Navbar CSS
** -Login CSS
** - Main Banner CSS
** - footer CSS
** - CountdownCircle 
** -Youthluuc
*/
/*================================================
Default CSS
=================================================*/
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Caption:wght@400;700&display=swap');:root {
  --main-color:#1F284F;
  --scond-color:#2C92D0;
  --therd-color:#2E93D1;
  --fourth-color:#B729A8;
  --yellow-color:#FEC053;
  scroll-behavior: initial;
}
.logo{
 height: 5.6rem;
}
body {
  font-family: 'Raleway', sans-serif;
    padding: 0;
  margin: 0;
  font-size: 15px;
}

.d-table {
  width: 100%;
  height: 90%;
  
}

.d-table-cell {
  vertical-align: middle;
  
}
.h1, .h2{
  font-weight: 700;
  color: #1F284F;

}
 .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 650;
  color: #1F284F;

}
/*section-title*/
.section-title {
  position: relative;
  margin-bottom: 60px;
  z-index: 1;
  text-align: start;
}

.section-title span {
  color: #3a3939;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 16px;
  text-transform: uppercase;
  display: block;
  
}

.section-title h1 {
  font-size: 40px;
  margin-top: 10px;
  margin-bottom: 10px;

  color: #1F284F;

}

.section-title h2 b {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(80%, rgba(0, 0, 0, 0)), color-stop(80%, var(--scond-color)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, var(--scond-color) 80%);
  font-weight: unset;
  background-size: 100px 1.07em !important;
}


/*================================================
Navbar CSS
=================================================*/
.navbar{
  font-family: 'PT Sans Caption', sans-serif;  background-color: transparent;
}
.elkevent-mobile-nav {
  display: none;
}

.elkevent-mobile-nav.mean-container .mean-nav ul li a.active {
  color: var(--scond-color);
}

.elkevent-nav {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: auto;
}

.elkevent-nav nav ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  justify-content: center;
}

.elkevent-nav nav .navbar-nav .nav-item {
  position: relative;

  align-self: center;
}
 .show > .navbar-nav  > .nav-item {
  margin: 3%;
  

  }
.elkevent-nav nav .navbar-nav .nav-item a {
  font-weight: 800;
  font-size: 16px;
  color: var(--main-color);
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 1px solid white;
  text-decoration: none;

}

.elkevent-nav nav .navbar-nav .nav-item a:hover, .elkevent-nav nav .navbar-nav .nav-item a:focus, .elkevent-nav nav .navbar-nav .nav-item a.active {
  color: black;

}

.elkevent-nav nav .navbar-nav .nav-item:hover a {
  color:var(--main-color);
  cursor: pointer;


}
.show {

  z-index: 999;
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
          box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  background-color: white !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
          animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  
}

.elkevent-nav.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  z-index: 999;
  border-bottom: 0.01rem solid rgb(184, 184, 184);
  -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
          box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  background-color: white !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
          animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.text-start{
  position: absolute;
  top: 30px; /* adjust this value to change the vertical position */
  right:.1rem; 
  border-radius: 6%;

}
.card {
  overflow: auto;
}

#circle-avatar {
  overflow: hidden;

  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #ffbd30;

 
}
.image-avatar {
  border-radius: 50%;
  object-fit:fill;
 
}
#avatar {
  overflow: hidden;
  width: 200px;
  height:200px;
  border-radius: 50%;
  background-color: #ffbd30;
  display: flex;
  justify-content: center;
  align-items: center;

 
} 

#avatar2{  
  width: 15rem;
  height:15rem;


} 
#avatar2 > span{
  font-size: 25px;
  font-weight: bold;
}
.avatar2{  
  overflow: hidden;
  width: 150px;
  height:150px;
  border-radius: 50%;
  background-color: #ffbd30;
  display: flex;
  justify-content: center;
  align-items: center;
} 
.avatar2 > span{
  font-size: 25px;
  font-weight: bold;
}


#avatarcarouselparticipant{  
  overflow: hidden;
  width: 150px;
  height:150px;
  border-radius: 50%;
  background-color: #ffbd30;
  display: flex;
  justify-content: center;
  align-items: center;


} 
#avatarcarouselparticipant > span{
  font-size: 25px;
  font-weight: bold;
}

.avatarmini{
  width: 100%;
  height: 100%;
  object-fit:fill;

  border-radius: 50%;

} 
#caract > span{
  font-size: 20px;
  font-weight: bold;
}
#caract{  
  display: flex;
  justify-content: center;
  align-items: center;
  

  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffbd30;
 
cursor: pointer;}
#moni-circle-avatar
{
  
  

  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffbd30;
 
cursor: pointer;
 
} 
#moni-circle-avatar>span
{

  font-size: 20px;
  font-weight: bold;
margin-top: 29px;
 
}
#avatar > span{
  font-size: 40px;
  font-weight: bold;
}
#avatar {
 

}

.profil-menu > .emailstyle{
  color: #88878ae3	;
  font-size: 12px;
}
.profil-menu  .Gerer-profil-button{

  color: var(--main-color);
border: 1px solid gray;
border-radius: 5px;
padding: 7px;
}
.profil-menu  .Gerer-profil-button:hover{
  background-color: rgb(238, 234, 234);

  color: var(--main-color);
border: 1px solid gray;
border-radius: 5px;
padding: 7px;
}
.profil-menu  .logout-profil-button{
margin-top: 5%;
  color: var(--main-color);
font-weight: 600;

}
.emailenvoyer{
  padding: 2%;
  margin: auto;
  width: 50%;
  display: flex;

  color: #4caf50;
}

.button-logout {
  padding: 1%;
  width: 100%;
  cursor: pointer;
}
.button-logout:hover{


background-color: rgb(238, 234, 234);
}
.UserImage {
  position: relative;
  z-index: 33;
  margin: auto;
;
}
.profilimage{
  position: relative;
  z-index: -1;

}
.picker{
justify-content: center;
align-items: center;
z-index: 1;
position: absolute;
width: 6%;
height: 7%;
right:30%;
bottom: 2rem;
cursor: pointer;

}

.picker >label {
  padding: .5rem;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
background-color: rgb(238, 234, 234);
border-radius: 50%;
     }
.picker >label > svg{
  
cursor: pointer;
}
@media screen and (max-width:991px) {
  .UserImage {
   width: 35%;
  }
  .profil{
    margin-top: 20%;
  }
  .UserInfo h5 ,h6{
    font-size: 15px;
  }
 
 .UserInfo > div:first-child{
display: flex;        
text-align: center;
  }
.UserInfo .info {
flex:2 ;
}
.UserInfo svg {
    flex: 1;
}
 
}
.UserInfo > div:first-child{
  display: flex;
  align-self: center;
 justify-self: start;
}

.UserInfo .info {
  flex:2 ;
   }
 .UserInfo svg {
  flex: 1;
   }

.logout-profil-button {
  cursor: pointer;
}
.settingicon{
  align-self: center;
 

}
/*================================================
Main Banner CSS
=================================================*/
.main-banner {
margin-top:5%;
  height: 100vh;
  position: relative;
  z-index: 1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.main-banner::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;

  opacity: .77;
}

.main-banner-content ul li:last-child {
  margin-right: 0;
}

.main-banner-content p {
  color: #575757;
  font-size: 22px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}


.main-banner-content.banner-content-center {
  margin: 50px auto 0;
  text-align: center;
}

.shape1 {
  z-index: -1;
position: absolute;
width: 160px;
height: 165px;
left: -6%;
bottom:10%;
border-radius: 50%;
/* Blue */
background: rgba(0, 112, 240, 0.9);
opacity: 0.3;
-webkit-animation: movebounce 1s linear infinite;
animation: movebounce 5s linear infinite;

}

.shape2 {



position: absolute;
width: 114px;
height: 114px;
right: 39%;
bottom: 15%;

/* Text Secondary */
background: #a0a0a0;
opacity: 0.3;
transform: rotate(-45deg);
border-radius: 50%;

-webkit-animation: movebounce 1s linear infinite;
animation: movebounce 5s linear infinite;
}

.shape3 {
/* Rectangle */

position: absolute;
width: 20px;
height: 20px;
right: 48%;
bottom: 10%;
border-radius: 50%;

/* Accent Color/Accent 3 */
background: #5956E9;
-webkit-animation: movebounce 1s linear infinite;
animation: movebounce 5s linear infinite;
}

.shape4 {

position: absolute;
width: 48px;
height: 48px;
left: -2%;
top: 10%;
border-radius: 50%;
-webkit-animation: movebounce 1s linear infinite;
animation: movebounce 5s linear infinite;
/* Blue */
background: rgba(0, 112, 240, 0.9);


}

.rotateme {
  -webkit-animation-name: rotateme;
          animation-name: rotateme;
  -webkit-animation-duration: 40s;
          animation-duration: 40s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes movebounce {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@-webkit-keyframes colorchange {
  0% {
    color: var(--scond-color);
  }
  25% {
    color: #ffffff;
  }
  50% {
    color: red;
  }
  75% {
    color: var(--therd-color);
  }
  0% {
    color: var(--yellow-color);
  }
}

@keyframes colorchange {
  0% {
    color: var(--scond-color);
  }
  25% {
    color: #ffffff;
  }
  50% {
    color: red;
  }
  75% {
    color: var(--therd-color);
  }
  0% {
    color: var(--yellow-color);
  }
}
.mainImage{
  width: 50%;
margin-top: 2%;
}
.mainImage > img{
  width: 90%;
  border-radius: 15px ;

}
@media screen and (max-width:991px) {
  .mainImage{
    width: 100%;
  margin-top: 2%;
  }
  .mainImage > img{
    width: 90%;
    border-radius: 15px ;
  
  }
  .section-title {
    position: relative;
    margin-top:8%;
    z-index: 1;
    text-align: center;
  }
  .logoImage{
    width: 100%;
  }
  .main-banner {
    margin-top: 2%;
      height: 140vh;
      position: relative;
      z-index: 1;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  .shape2 {
   position: absolute;
   width: 114px;
   height: 114px;
   right: 30%;
   bottom: 9%;
   background: #a0a0a0;
   opacity: 0.3;
   transform: rotate(-45deg);
   border-radius: 50%;
   -webkit-animation: movebounce 1s linear infinite;
   animation: movebounce 5s linear infinite;
   }
   
  .shape3 {
   display: none;
   position: absolute;
   width: 20px;
   height: 20px;
   right: 48%;
   bottom:5%;
   border-radius: 50%;
   background: #5956E9;
   -webkit-animation: movebounce 1s linear infinite;
   animation: movebounce 5s linear infinite;
   }
  
}
@media screen and (max-width:531px) {
 
.shape4 {
  position: absolute;
  width: 48px;
  height: 48px;
  left: -7%;
  top: 15%;
  border-radius: 50%;
  -webkit-animation: movebounce 1s linear infinite;
  animation: movebounce 5s linear infinite;
  background: rgba(0, 112, 240, 0.9);
  
  }
  .shape1 {
    z-index: -1;
  position: absolute;
  width: 160px;
  height: 165px;
  left: -20%;
  bottom:10%;
  border-radius: 50%;
  background: rgba(0, 112, 240, 0.9);
  opacity: 0.3;
  -webkit-animation: movebounce 1s linear infinite;
  animation: movebounce 5s linear infinite;
  
  }

}
@media screen and (max-width:409px) {
 
  .shape4 {
    position: absolute;
    width: 48px;
    height: 48px;
    left: -7%;
    top: 15%;
    border-radius: 50%;
    -webkit-animation: movebounce 1s linear infinite;
    animation: movebounce 5s linear infinite;
    /* Blue */
    background: rgba(0, 112, 240, 0.9);
    
    
    }
    .shape1 {
      z-index: -1;
    position: absolute;
    width: 160px;
    height: 165px;
    left: -30%;
    bottom:10%;
    border-radius: 50%;
    /* Blue */
    background: rgba(0, 112, 240, 0.9);
    opacity: 0.3;
    -webkit-animation: movebounce 1s linear infinite;
    animation: movebounce 5s linear infinite;
    
    }
    
  }
  
.btn{

  padding: 6px;
  position: relative;
  cursor: pointer;
  color: white;
  width: 110px;
  height: 40px;
font-size: 1rem;
   background:  #2C92D0;
    transition: all 0.4s linear;
    font-weight: 400;
    border-radius: 30px;

  }


.btn:hover{ 
  color: #2C92D0;
     background: none;
}
.btn:before,
.btn:after{
  border-radius: 30px;

  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  z-index: -1;
  background: white;
  color: #2C92D0;
  font-size: 1rem;

  transition: all 0.2s linear;
}
.one{

}
.one:before{ 
  
  top: 0; left: 0; }

.one:hover:before{ width: 100%; }

/*================================================
Footer CSS
=================================================*/
.logi{

  display: flex;
 justify-content: center;

 
}
.logi >  img{

width: 100%;
 
}
.footer-area {
  border-top: 1px solid rgb(209, 209, 209);

  background-color: #fffffffa;

  /* padding-top: 120px; */
}

.copyright-area  {  
  
  align-items: center;
  border-top:2px solid rgba(255, 255, 255, 0.075);
  margin-bottom: 2%;
  padding-bottom: 30px;
  margin-top: 2%;
text-align: center;
}

.copyright-area h5 {
  color:black;
}
.social{
 border-radius: 50%;
 color:black;
 cursor: pointer;
}

.copyright-area p {
  color: gray;
  font-weight: 500;
}
.logo-image{
  width:80%;

}
.logo-imagegiz{
  width:50%;
height: 150px;
margin: auto;
}
.Reachus{
width: 81.5px;
height: 13.48px;
color: #212325;
flex: none;
order: 0;
flex-grow: 0;
}
.socialicons{
  text-decoration: none;
  color: black;
}

/*================================================
7aja o5ra CSS
=================================================*/


.icon{
  background-color: #187EF1;
}
.box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}
@media screen and (max-width:767px) {
  .box {
    display: flex;
    flex-direction: column;
align-items: center;


margin: auto;
width: 50%;

  }
  
  }
  
/*================================================
Youthluuc
=================================================*/

  .youtlucc{
      padding: 6%;
  }
  .youthvideo{
    height:22rem;
    width:100%;

  }
  .video{
    flex: 2;
    margin: auto;
    display: flex;
    justify-content: center;
    
  }
  .collection{

    flex: 1;

 margin: auto;
  }
  .collection-item{

cursor: pointer;
margin-top: 1%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    
    /* Line 7 (Stroke) */


  }
  .collection-item:hover{
opacity:0.8;
    cursor: pointer;
    margin-top: 1%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        
        /* Line 7 (Stroke) */
    border-bottom: 1px solid #161616;
    
    
      }
  .collection-item-image{
    width:43%;
    margin-right: 2%;
  }
  .collection-item-image-titel{
    width: 60%;
    display: flex;
    align-items: center;
   
  }
  .title{
    font-weight: bold;
  }

  @media screen and (max-width:992px) {
    .contenu{
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
    }
    
  }
/*================================================
Kidsluuc CSS
=================================================*/
  /* kidsluuc */
  .kidsluuc{      margin-top: 2%;

    height: 60%;
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .slide-item{
/* Ajoute une marge de 10 pixels autour de chaque élément du slider */

width: 100%;


  }
  .carsoul{

    display: flex;
   width: 90%;
  }
  .kidsluuc > .shape1 {
    z-index: -1;
  position: absolute;
  width: 160px;
  height: 165px;
  left: 60%;
  bottom:10%;
  border-radius: 50%;
  /* Blue */
  background: #B729A8;
  opacity: 0.3;
  -webkit-animation: movebounce 1s linear infinite;
  animation: movebounce 5s linear infinite;
  
  }
  
  .kidsluuc > .shape2 {
  
  
  
  position: absolute;
  width: 114px;
  height: 114px;
  right:  90%;
  bottom: 15%;
  
  /* Text Secondary */
  background: #F2203E;
  opacity: 0.3;
  transform: rotate(-45deg);
  border-radius: 50%;
  
  -webkit-animation: movebounce 1s linear infinite;
  animation: movebounce 5s linear infinite;
  }
  
  .kidsluuc > .shape3 {
  /* Rectangle */
  
  position: absolute;
  width: 20px;
  height: 20px;
  right: 48%;
  bottom: 10%;
  border-radius: 50%;
  
  /* Accent Color/Accent 3 */
  background: #5956E9;
  -webkit-animation: movebounce 1s linear infinite;
  animation: movebounce 5s linear infinite;
  }
  
  .kidsluuc > .shape4 {
  
  position: absolute;
  width: 48px;
  height: 48px;
  left: -2%;
  top: 15%;
  border-radius: 50%;
  -webkit-animation: movebounce 1s linear infinite;
  animation: movebounce 5s linear infinite;
  /* Blue */
  background: #FEC053;
  
  
  }

  .custom-flex-container {
    display: flex;
    align-items: center;
  }
  
  .custom-center-content {
    margin: auto;
  }
  /*================================================
Login CSS
=================================================*/
.logoImagelogin{
width: 50%;
}
.forgot-pass{
  cursor: pointer;
  text-decoration: none;
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;
color: var(--main-color);
border-bottom: 1pw solid transparent;
flex: none;
order: 1;
flex-grow: 0;
}

.labellogin{
text-align: start;
padding: .3rem;
}
.labellogin > label{
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
color: #515458;
 }
.form-control{

  border-radius: 8px;
  padding: .5rem 2%; line-height: 28px;
}
.form-controlmotdepasse{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgb(211, 211, 211);

  border-radius: 8px;
  padding: .5rem 2%; line-height: 28px;
}
.form-control-motdepasse-input{
border: none;
}
.lesapan{
  align-self: center;

}
.form-control-motdepasse-input:focus{
  outline: none;
}
.login > h4{
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 32px;
/* identical to box height, or 133% */
text-align: center;

color: #17181A;


/* Inside auto layout */
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

}
.logintitle{  
   position: relative;
  z-index: 1;
background-color:#EEF8FA ;
display: flex;
flex-direction: column;
Justify-Content: center;}
.loginsection{

  margin: auto;
text-align: center;
}
.loginsection >button{
  width: 100%;
}
.logintitle-minisection{
  
height: 50vh;
  width: 80%;
  margin: auto;
}
.logintitle-minisection>h1{
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 64px;
/* or 133% */

/* Text Primary */
color: #17181A;


}
.logintitle-minisection>h1>b{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 64px;
  color: #17181A;
  
  }
.logintitle-minisection>img{
  width: 50%;
}
.content-login {
  height:100%;
  width:100%;
  position:absolute
}
.loginsection>p{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
text-align: center;
color: #575757;
flex: none;
order: 1;
flex-grow: 0;
}

 .luucshape {
    z-index: -1;
    position: absolute;
    width: 100px;
    height: 105px;
    right: 15%;
    top:27%;
    /* Blue */
  
  }
  .luucshapelogin > img{
    width: 100%;
    height: 100%;

  }
  .luucshapelogin {
    z-index: -1;
    position: absolute;
    width:299px;
    height: 105px;
    right: 15%;
    bottom:27%;
    /* Blue */
  }
  .logominidev{
   display: none;
  }
  .logomini{
    display: none;
  }
  .loginsection > .luucshape{
    display: none;
  }
  .exempltel{
    font-style: italic;
    color: gray;
  
  }
  @media screen and (max-width:991px) {
   .logintitle {
    display: none;
   }
   .logominidev{

display:block;

display: flex; /* or inline-block, depending on your layout */
justify-content: center; /* aligns the image horizontally */
align-items: center; /* aligns the image vertically */


  }
  .logomini{
    display:block;
    width: 50%;
  }
  .loginsection{ 


   
 
}

.singup{

  padding: auto;

  justify-content: center;
  align-items: center;
}

  }
  .patinerpasswordicon{

    background-color: red;
  }
  .patinerpasswordicon > svg{
    margin: 10px;
  }
  .patinerpassword{
    display: flex;
    border-radius: 5px;
    margin: 2%;
    border: 1px solid red;
  }
  .patinerpassword p{
    margin: 10px;
   
  }
.btn-continuer-annuler{
  display: flex;
  align-items: center;
}
.annulerbttn{
  margin-top: 3%;
  background-color:#868484;
}
 /*================================================
  Profil CSS
=================================================*/
  .d-table-profil {
    margin-top: 15%;
    margin-bottom: 5%;
    
  }
  .d-table-cell {
    vertical-align:middle;
  }
  .UserInfo h6{
    font-size: 20px;
  }
  .UserInfo .info > .emailstyle{
    color: #88878ae3	;
  }


  .UserInfo div:first-child{
  margin-bottom: 4%;
}
.UserInfo >div  div:nth-child(2) svg:first-child
{
  cursor: pointer;

}
.profilimage2{
  width: 100%;
  height: 100%;
  object-fit:fill;
  border-radius: 50%;
}
  .profilimage{
    width:100%;
    border-radius: 20%;
  }
  .tow{
    width: 10rem;
    margin: .5rem;
    height: 2.3rem;
  }

  .space{  

    height: 10%;
  } 
   .forum > form{
    margin-bottom:5rem;
   display: grid;
  }
  .lienDrive  p{
font-size: 14px;
color: gray;
font-weight: 400;
font-style: italic;
  }
  .lienDrive > label{


    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: .4rem;
    color: #3e4044;
    
    
    
    
      
      
      
      
      }
     
/* etat */
.sujetdescription{

  border: 1px solid var(--scond-color);
  border-radius: 10px;
  max-width:40% ;

  padding: 2%;
margin: auto;}

.propostion-image{
display: flex;
}
.propostion-image > img{
margin: auto;
width: 50%;

}
.propostion-title-etat{
  display: flex;

  justify-content: space-between;
padding-inline: 2%;

}

.edit{
  margin-top: 15%;
  text-align: center;
}
.edit >b{
  cursor: pointer;
}
.edit >b:hover{

  border-bottom:3px solid black;
  cursor: pointer;
}
.etat{
  
border:  #F2F2F2 solid .1rem;

}
.etat .etattype{
  font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 20px;
line-height: 60px;
text-align: right;
color: #000000;
}
.etat > h6{
  

font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 60px;
/* identical to box height, or 120% */

/* Primary Color */
color: #1F284F;
}
.d-tableetatproposition{
  width: 100%;
  height: 80%;
}


section {
  width: 100%;
  max-width: 420px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  background-color: rgba(0,0,0,0.4);
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  padding-bottom: 1rem;
}

a, a:visited {
}

input[type="text"],
input[type="password"],
button,
textarea {
font-family: 'Nunito', sans-serif;
font-size: 22px;
padding: 0.25rem;
border-radius: 0.5rem;
}

label,
button {
margin-top: 1rem;
}

button {
padding: 0.5rem;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
  text-align: center;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}

.phraseclignotante{
  cursor: pointer;
  display: flex;
   margin-top: 2%;

  animation: blink 2.5s infinite;
}


@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.text {
  margin-left: .5rem;
  font-weight: bold;
  color: Crimson;
}


/*================================================
  Not found CSS
=================================================*/


.error-area {
  background-color: #f1f1f1;
  padding: 30px 15px;
  height: 100vh;
  text-align: center;
  z-index: 10000;
  position: relative;
}

.error-area h1 {
  font-size: 150px;
  font-weight: bold;
  line-height: 110px;
  margin: 0;
  color: var(--scond-color);
}

.error-area h1 span {
  color: var(--yellow-color);
}

.error-area h1 b {
  font-weight: unset;
  color: var(--fourth-color);
}

.error-area h3 {
  margin: 35px 0 15px;
}

.error-area p {
  margin: 10px 0 20px;
}
.not-found-btn{
  width: 30%;
}
/*================================================
  Proposition-detail CSS
=================================================*/

.videoframe{
  height:35rem;
  width:100%;

}

.socialshare{
  border: 1px   solid   black;
  display: grid;
 
  place-items: center;
  border-radius: 50%;
  width: 1.9rem;
  height: 1.9rem;
  color:black;
cursor: pointer;
}



/* proposition editor */


.containereditor{
  position:relative;
  height: 100vh;
  width: 100%;
}

.roweditor{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.editor {
  position: relative;
  height: 100%;
  width: 50%;
  display: flex;
  align-self: center;
  justify-content: center;
}


/*================================================
  Participant  CSS
=================================================*/
.card {
margin-right: 10%;
  margin-top: 2%;
  display: flex;
  align-items: start;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
 
  

}
.participant-card{
height:29rem;
margin-bottom:3%;

}
.participant-info-card{
  display: flex;
  align-items: center;
  padding: 10px;

  border-radius: 10px;
}
#avatardeimageprofil {
  overflow: hidden;
  width: 15rem;
  height:15rem;
  border-radius: 50%;

  display: flex;
  margin-right: 10px;
  justify-content: center;
  align-items: center;

 
}
.avatar {
  
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  /* margin-right: 10px; */
}


.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.name {
  margin-left:10px;
  margin-top: 0;
}
.card-content{
  margin-top:1%;
  
}
.card-content-arabe{
  align-self: flex-end;
 
  direction: rtl;
  margin-top:1%;
  
}
.details-btn{
  position: absolute;
align-self: flex-end;
bottom: 0;
 
}
.card-content-description{
  max-height: 100px; /* Adjust this value to fit your container's maximum height */
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 3; /* Adjust this value to control the number of lines to show */
  -webkit-box-orient: vertical;

}
.le-sujet{
max-height: 50px; /* Adjust this value to fit your container's maximum height */
overflow: hidden;
text-overflow: ellipsis;

display: -webkit-box;
-webkit-line-clamp: 3; /* Adjust this value to control the number of lines to show */
-webkit-box-orient: vertical;
}
.description {  color: #666;

  display: block;
  overflow:visible;
  text-overflow: ellipsis;
}


.card-content > p > span:first-child{
  font-family: 'Courier New', Courier, monospace;
  font-style: italic;
  color: var(--main-color);
  font-weight: 800;
}
.card-content > p > span:last-child {
    font-family: 'Times New Roman', Times, serif;
font-size:medium;
color: #525252;
}





.participatmain{
  margin: 2%;
  height:120vh;
  padding-top: 6%;

}
.participatcont{
  margin: auto;
  width: 50%;
border: 1px  solid  red;
  justify-content: center;
}

.preview{
  position: relative;
  height: 100%;
  width: 50%;
  padding: 10px;
  overflow: scroll;
  border-left: 1px solid black;
}
.editor-input{
  height: 100%;
  width: 100%;
}
a {
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
}

.previous {
  background-color: #f1f1f1;
  color: black;
  cursor: pointer;
}

.next {
  background-color: #f1f1f1;
  color: black;
  cursor: pointer;

}

.round {
  border-radius: 50%;
}


/* .card{
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
margin: 50px;
 overflow: hidden;
  width: 50%;
  height:300px;

} */
.Slider{

margin:1%;
}
.card-info {
  background-color: #fff;
  padding: 20px;
  margin: auto;
  width: 70%;
  
  text-align: center;

}

.card-title {

  font-family: 'Rounded Mplus 1c Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 31px;
  line-height: 79.2%;
  /* or 29px */
  text-align: center;
  
  color: #264240;
  margin-bottom: 10px;
}

.card-desc {
margin-top: 1%;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 125.2%;
text-align: center;
letter-spacing: 0.01em;
color: #264240;


}
.Details{

/* Button_18 */
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 24px;

cursor: pointer;

/* Blue/main */
color: #0070F0;



}
.card-image {

   height: 300px;
  
}

.card-image img {
  height: 100%;


  width: 100%;
}
/*================================================
infographieImage CSS
=================================================*/

.infographieImageDiv{

  display: grid;
  place-items: center;

}

.infographieImage {
 margin:auto;
  width: 50%;

}

.propositionitem{
border: 1px solid gray;
 border-radius: 10px;
 padding: 1%;
 }
 .propositionitem-arabe{
  border: 1px solid gray;
 border-radius: 10px;
 padding: 1%;
  direction: rtl;
 }
 .comment-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid var(--therd-color);
}
.sujettitle{
  font-size: 18px;
   }

   .youthvideoitem{
    pointer-events: none;
  }
   
/*================================================
CountdownCircle  CSS
=================================================*/




#time{
  
padding: 3%;
justify-content: center;
  display: flex;
gap: 30px;

}
#time .circle{
  position: relative;
  width:150px;
  height: 150x;
  display: flex;
  justify-content: center;
  align-items: center;

}*

#time .circle svg{
position: relative;
/* width: 150px;
height: 150px; */
transform: rotate(270deg);
  
}
#time .circle svg circle{
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke-width: 8;
  stroke: white;
  stroke-linecap: round;
  transform: translate(5px,5px);


}
#time .circle svg circle:nth-child(2){
  stroke: var(--clr);
  stroke-dasharray: 440;
  stroke-dashoffset: 440;

}
#time div{

  position: absolute;
  text-align: center;
  font-weight: 500;
  color: var(--main-color);
  font-size: 1.5em;


}

#time div span  {

position: absolute;
transform: translateX(-50%) translateY(-10px);
font-size: 0.35em;
font-weight: 300;
letter-spacing: 0.1em;
text-transform: uppercase;
}

#time .dots{
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;

}
#time .dots::before{
  content: '';
  position: absolute;
  top: -3px;
 
  width: 15px;
  height: 15px;
  background: var(--clr);
  border-radius: 50%;
  box-shadow: 0 0 20px var(--clr), 
  0 0 60px var(--clr);


}
.alltime{justify-content: center;

  display: flex;

}
@media screen and (max-width:767px) {
 
    .alltime{
  
      justify-content: center;
        display: flex;
        flex-direction: column;
   
      
  }

}
/*vote */
.alltimetow{
  justify-content: center;
  display: flex;
}


.alltimetow p   {
text-align: center;
 
  font-weight:500;
  letter-spacing: 0.1em;
  }

.alltimetow p span  {

  transform: translateX(-50%) translateY(-10px);
  font-size: .90em;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  }
  
/**emailverifier**/
.verifierPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;

}
.emailverifier {
  padding: 5%;
  text-align: center;
  border: 1px solid var(--main-color);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  width: 50%;
  margin: auto;
  align-self: center;
}

.success-checkmark {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}



h2 {
  margin-top: 20px;
  color: #333;
}

.d-table-proposition {
  margin-top: 10%;
  margin-bottom: 5%;
  
}

.blog-card {
  display: flex;
  align-items:center;
  justify-content: center;
  padding: 20px;
width: 50%;
margin: auto;
}

.avatar {
  
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.content :first-child{

  display: flex;
  justify-content: space-between;
}

.name {
  margin-top: 0;
}

.icons-share {

  display:flex;
  margin-top: 100px;

  justify-content: end;
}
.icons-share > p{
  color: rgb(156, 156, 156);

}

.icons-share svg {
cursor: pointer;
}
.proposition-content{
  font-family: 'Noto Sans';
  line-height: 35px;
  margin-inline: 10%;
  margin-block: 5%;
}
.proposition-content-arabe{
  font-family: 'Droid Arabic Kufi';
  line-height: 35px;

  margin-inline: 10%;
  margin-block: 5%;
  direction:rtl
}
.proposition-title{
display: flex;
justify-content: space-between;
}

.proposition-title  p > span:first-child{
  font-family: 'Courier New', Courier, monospace;
  font-style: italic;
  color: var(--main-color);
  font-weight: 800;
}
.proposition-title  p > span:last-child {
    font-family: 'Times New Roman', Times, serif;
font-size:medium;
color: #525252;
}

.proposition-title{
  color: #1b1b1b;
  margin-bottom: 1%;
}
.read-more{
  text-align: end;
  cursor: pointer;
}
.blog div{
  display: flex;
  justify-content: end;
align-items: end;
}
.large-letter {
  font-size: 2.5em; /* Exemple de taille de police plus grande */
}
.videoDebat{
margin-bottom: 1%;
  display: flex;
  justify-content: center;
}
.InfographieImage{
  margin-bottom: 1%;

  display: flex;
  justify-content: center;
}
.card-titre{
  margin-top: 2%;
  font-size: 16px;
}

@media screen and (max-width:767px) {
  .blog-card {
    align-items: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items:start;
    justify-content: center;
    padding: 20px;
  width: 50%;
margin-top: 25%;
  }
  .icons-share {

    display:flex;
    margin-top: 1px;
  
    justify-content: end;
  }
  .content {
    height: 150px;
    justify-content:space-between;
  margin: auto;
  }
  .name{
    margin-top:15%;
  }
  .avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin:auto
  }
}
.vote-button{
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  display: flex;
  text-align: start;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 1%;
  cursor: pointer;
}